<template>
    <div class="home">
        <AppHeader 
            v-if="header" 
            :header="header" 
        />

        <div class="aboutUs" v-if="data">
            <div class="aboutUs__backgroundImage" :style="{ backgroundImage: `url(${ background })` }">
            <!-- <div class="aboutUs__backgroundImage"> -->
                <h1 class="page__subtitle">{{ data.title }}</h1>
            </div>

            <div class="aboutUs__industries">
                <div class="container">
                    <!-- <h1 class="home__title">{{ data.title }}</h1>
                    <span v-html="data.content" class="html_content"></span> -->
                    <p class="aboutUs__text">
                        Urtica Smart Solutions is a boutique consulting company. We focus on solving complex problems in Air Traffic Management and Telecommunications industries. We provide aviation CNS/ATM Consultancy and Training to Air Navigation Service Providers and Telecom OSS/BSS solutions to Telecom Service Providers in GCC and Central Asia.
                    </p>
                    <p class="aboutUs__text">
                        We help our customers to automate their processes and improve business continuity. Our consultancy solutions are bundled with our professional services and 24/7 managed support. Along with implementing our projects, we deliver consultancy services around IT strategy, IT architecture design, and business automation advice.
                    </p>

                    <div class="aboutUs__industries-points">
                        <p>Industries focus:</p>

                        <ul class="aboutUs__list">
                            <li class="aboutUs__list-item">
                                <img src="../assets/img/about-us/icon1.svg" alt="">
                                <p>Aviation:Our expertise in aviation spans control centers, control tower engagement, and partnerships with ANSPs. With UTM and ICWP at the core, our solutions aim to elevate aviation industry standards.</p>
                            </li>
                            <li class="aboutUs__list-item">
                                <img src="../assets/img/about-us/icon2.svg" alt="">
                                <p>Telecommunications:In collaboration with major OSS/BSS vendors, our telecom solutions guarantee seamless quality of service and customer experience, empowering the entire communication spectrum.</p>
                            </li>
                            <li class="aboutUs__list-item">
                                <img src="../assets/img/about-us/icon3.svg" alt="">
                                <p>Medicine:Healthcare digitized and optimized. From patient management systems to cutting-edge medical research platforms, we redefine healthcare IT.</p>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>

            <div class="aboutUs__services">
                <div class="container">
                    <div class="aboutUs__industries-points">
                        <p>IT Services portfolio:</p>

                        <ul class="aboutUs__list">
                            <li class="aboutUs__list-item">
                                <img src="../assets/img/about-us/icon4.svg" alt="">
                                <p>IaaS:Infrastructure as a Service focusing on scalability, reliability, and security. Leverage our infrastructure to scale your business seamlessly.</p>
                            </li>
                            <li class="aboutUs__list-item">
                                <img src="../assets/img/about-us/icon5.svg" alt="">
                                <p>Data Backup and Disaster Recovery:Secure your business-critical data and ensure rapid recovery during unforeseen contingencies with our top-notch services.</p>
                            </li>
                            <li class="aboutUs__list-item">
                                <img src="../assets/img/about-us/icon6.svg" alt="">
                                <p>Cloud Services:Embrace the cloud. Optimize costs, enhance agility, and scale with our specialized cloud solutions.</p>
                            </li>
                            <li class="aboutUs__list-item">
                                <img src="../assets/img/about-us/icon7.svg" alt="">
                                <p>BI Systems:Transform raw data into insightful analytics. Our Business Intelligence systems offer actionable insights, driving informed business decisions.</p>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>

            <div class="aboutUs__consultancy">
                <div class="container">
                    <div class="aboutUs__industries-points">
                        <p>Consultancy expertise:</p>

                        <ul class="aboutUs__list">
                            <li class="aboutUs__list-item">
                                <img src="../assets/img/about-us/icon8.svg" alt="">
                                <p>Aviation ATM and CNS Consultancy:Leverage our deep industry insights in Aviation's ATM and CNS sectors to ensure compliance, efficiency, and forward momentum.</p>
                            </li>
                            <li class="aboutUs__list-item">
                                <img src="../assets/img/about-us/icon9.svg" alt="">
                                <p>Enterprise Network Architecture:Our enterprise network consultancy service is tailored to provide robust, scalable, and agile network solutions fitting your business</p>
                            </li>
                            <li class="aboutUs__list-item">
                                <img src="../assets/img/about-us/icon10.svg" alt="">
                                <p>Data Strategy:Harness the power of data with a coherent and robust strategy. Unlock your business's potential and drive growth with data-driven decisions.</p>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>

        <SmallFooter 
            v-if="footer" 
            :footer="footer" 
        />
    </div>
</template>

<script>
import axios from "axios";
import { BASE_URL } from "@/constants/constants.js"
import { mapActions } from "vuex";
import { actionTypes } from "@/store";
export default {
    name: "AboutUs",
    components: {
        AppHeader: () => import("@/components/common/AppHeader.vue"),
        SmallFooter: () => import("@/components/common/SmallFooter.vue"),
    },
    data() {
        return {
            header: null,
            footer: null,
            data: null,
            background: require("@/assets/img/about-us/about-us-background-1.jpg")
        };
    },
    created() {
        axios(`${BASE_URL}/api/header`, {
            method: "GET",
        }).then((res) => {
            this.header = res.data;
        });

        axios(`${BASE_URL}/api/footer`, {
            method: "GET",
        }).then((res) => {
            this.footer = res.data;
        });

        axios(`${BASE_URL}/api/page${this.$route.path}`, {
            method: "GET",
        }).then((res) => {
            this.data = res.data[0];
        });
    },
    methods: {
        ...mapActions({
            openModal: actionTypes.getIsShowModal,
        }),
    },
}
</script>

<style lang="scss" scoped>
@import "@/assets/styles/common/adaptive-value.scss";

main {
    @include adaptive-value('margin-left', 126, 15, 1);
    @include adaptive-value('margin-right', 126, 15, 1);
    @include adaptive-value('margin-bottom', 48, 30, 1);
}
</style>
