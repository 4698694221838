<template>
    <div class="home">
        <AppHeader v-if="header" :header="header" />

        <!-- <main v-if="data">
            <div class="container">
                <h1 class="home__title">{{ data.title }}</h1>
                <div v-html="data.content" class="html_content"></div>
            </div>
        </main> -->

        <div class="industry">
            <div class="aboutUs__backgroundImage" :style="{ backgroundImage: `url(${background})` }">
                <h1 class="page__subtitle">{{ data.title }}</h1>
            </div>
            <div class="container">
                <img class="industry__icon" src="../assets/img/about-us/icon12.svg" alt="">
            </div>

            <div class="industry__background" :style="{ backgroundImage: `url(${background2})` }">
                <div class="container">
                    <div class="industry__block">
                        <p class="industry__text">Our Distinctive Approach to Information Management Strategy:</p>
                        <p class="industry__text">
                            <span style="font-weight: 700; font-size: 25px;">
                                1. Data Governance:
                            </span> 
                            Ensuring Integrity from the Ground Up. It's not just about having data; it's about governing it with precision. We help businesses define clear organizational structures, implement rigorous policies, establish standards, and delineate the roles and responsibilities that ensure data remains consistent, compliant, and valuable.
                        </p>
                        <p class="industry__text">
                            <span style="font-weight: 700; font-size: 25px;">
                                2. Data Operations:
                            </span> 
                            Bridging Technical Expertise with Functional Relevance. Our approach separates operations into two critical spheres: technical and functional. While the technical dimension focuses on the granular aspects of data processing and storage, the functional side emphasizes the nexus between business and IT systems, ensuring continuous alignment and efficient change management.
                        </p>
                        <p class="industry__text">
                            <span style="font-weight: 700; font-size: 25px;">
                                3. Data Architecture:
                            </span> 
                            Blueprinting the Data Landscape. Our experts craft data structures purposefully, promoting seamless, secure, and efficient data flow across systems. By optimizing the interplay of diverse data elements, we enable businesses to be more agile and responsive.
                        </p>
                        <p class="industry__text">
                            <span style="font-weight: 700; font-size: 25px;">
                                4. Data Management:
                            </span> 
                            Harmonizing Metadata for Transparent Interactions. Whether it's business-centric or technically inclined metadata, our strategies ensure its meticulous maintenance. From promoting transparency in relationships to implementing cross-system data lineage, we ensure consistency throughout. This also extends to mastering data management, creating a single source of truth for businesses.
                        </p>
                        <p class="industry__text">
                            <span style="font-weight: 700; font-size: 25px;">
                                5. Data Quality:
                            </span> 
                            Turning Raw Data into Trusted Insights. Data, in its raw form, can often be chaotic. We help businesses refine this rawness, assessing data based on accuracy, completeness, reliability, relevance, and timeliness, ensuring that every piece of information is primed for decision-making.
                        </p>
                        <p class="industry__text">
                            <span style="font-weight: 700; font-size: 25px;">
                                6. Data Security:
                            </span> 
                            Guarding the Crown Jewels. In the age of cyber vulnerabilities, data security is paramount. Our strategies encompass comprehensive measures, from access management to advanced encryption techniques, ensuring that data remains inviolate against threats. Urtica's expertise in data strategy for ANSP is unparalleled. With a deep understanding of the industry's unique demands and challenges, our consultancy ensures that ANSP entities are equipped with data strategies that are both future-proof and actionable.
                        </p>
                    </div>
                </div>
            </div>
        </div>

        <SmallFooter v-if="footer" :footer="footer" />
    </div>
</template>

<script>
import axios from "axios";
import { BASE_URL } from "@/constants/constants.js"
export default {
    data() {
        return {
            header: null,
            footer: null,
            data: null,
            background: require("@/assets/img/data-strategy/background-1.webp"),
            background2: require("@/assets/img/data-strategy/background-2.webp")
        }
    },
    components: {
        AppHeader: () => import("@/components/common/AppHeader.vue"),
        SmallFooter: () => import("@/components/common/SmallFooter.vue"),
    },
    mounted() {
        axios(`${BASE_URL}/api/header`, {
            method: "GET",
        }).then((res) => {
            this.header = res.data;
        });

        axios(`${BASE_URL}/api/footer`, {
            method: "GET",
        }).then((res) => {
            this.footer = res.data;
        });

        axios(`${BASE_URL}/api/page/data_strategy`, {
            method: "GET",
        }).then((res) => {
            this.data = res.data[0];
        });
    },
}
</script>

<style lang="scss" scoped>@import "@/assets/styles/common/adaptive-value.scss";

main {
    @include adaptive-value('margin-left', 126, 15, 1);
    @include adaptive-value('margin-right', 126, 15, 1);
    @include adaptive-value('margin-bottom', 48, 30, 1);
}</style>
