<template>
    <div class="home">
        <AppHeader v-if="header" :header="header" />

        <!-- <main v-if="data">
            <div class="container">
                <h1 class="home__title">{{ data.title }}</h1>
                <div v-html="data.content" class="html_content"></div>
            </div>
        </main> -->

        <div class="industry">
            <div class="aboutUs__backgroundImage" :style="{ backgroundImage: `url(${background})` }">
                <h1 class="page__subtitle">{{ data.title }}</h1>
            </div>

            <div class="container">
                <div class="industry__block">
                    <img class="industry__icon" src="../assets/img/about-us/icon1.svg" alt="">

                    <div class="industry__content">
                        <h2 class="industry__title">Urtica Smart Solutions & Aviation</h2>
                        <p class="industry__text">Embarking on uncharted horizons, Urtica Smart Solutions champions the symbiosis of aviation and cutting-edge technology. With an extensive portfolio that delves deep into the heart of the aviation ecosystem, our mastery lies not just in the skies but also in orchestrating the intricate symphony on the ground.</p>

                        <h2 class="industry__title">Our Approach:</h2>
                        <p class="industry__text">In aviation, one size does not fit all. Every airspace, every radar station, and every communication tower presents its set of intricacies. With this understanding, we tailor-make solutions, ensuring each component and system seamlessly complements the other.</p>

                        <h2 class="industry__title">Notable Implementations:</h2>
                        <ul class="industry__list">
                            <li>ATM Skyline System Upgrade by Leidos: We've transformed air traffic management landscapes by updating the pivotal ATM Skyline system in collaboration with Leidos.</li>
                            <li>Incorporation of Aireon's Space-based ADS-B Service: Our integration of the trailblazing Space-based ADS-B service from Aireon has reshaped airspace monitoring paradigms.</li>
                            <li>With real-time data at the forefront, our endeavor has empowered controllers with an unmatched level of operational visibility.</li>
                        </ul>
                        <p>Holistic Integration of the UFA Training Complex: By assimilating UFA's avant-garde training complex, we've elevated training standards, guaranteeing that aviation personnel stay abreast of contemporary tools and knowledge.</p>
                    </div>
                </div>             
            </div>

            <div class="industry__background" :style="{ backgroundImage: `url(${ background2 })` }">
                <div class="container">
                    <div class="industry__block">
                        <h2 class="industry__title">Beyond The Visible Horizon:</h2>
                        <p class="industry__text">With expertise in UTM and ICWP, our vision extends beyond the immediate. From architecting state-of-the-art control infrastructure to fostering cohesive collaborations with control units, our endeavors continually redefine operational excellence.</p>

                        <h2 class="industry__title">Regulatory Mastery & Global Benchmarks:</h2>
                        <p class="industry__text">In an industry as regulated as aviation, compliance isn't an option but a mandate. We pride ourselves on devising solutions that meet and often exceed standards set by pivotal entities such as GCAA, ICAO, EASA, and FAA. Our strategies are rooted in global best practices, ensuring innovation without compromise.</p>

                        <h2 class="industry__title">Unparalleled Collaborations:</h2>
                        <p class="industry__text">The testament to our prowess is our strategic alliances. By aligning with global frontrunners like Leidos, Aireon, and UFA, we guarantee that our propositions aren't just technologically superior but are shaped with the wisdom of industry leaders.</p>

                        <h2 class="industry__title">Pioneering The Future:</h2>
                        <p class="industry__text">As the aviation landscape metamorphoses, we aren't mere spectators but catalysts of change. Our continuous efforts in R&D, coupled with elite collaborations, position us as the vanguard of aviation evolution.</p>

                        <h2 class="industry__title">Together, We Achieve Excellence:</h2>
                        <p>Urtica Smart Solutions thrives on forging partnerships. If your endeavors in aviation seek efficiency, innovation, and unparalleled expertise, it's time for a dialogue. Together, we can craft solutions that don't just soar but redefine the boundaries of possibility.</p>
                    </div>   
                </div>
                
            </div>
        </div>

        <SmallFooter v-if="footer" :footer="footer" />
    </div>
</template>

<script>
import axios from "axios";
import { BASE_URL } from "@/constants/constants.js"
export default {
    data() {
        return {
            header: null,
            footer: null,
            data: null,
            background: require("@/assets/img/aviation/background-1.webp"),
            background2: require("@/assets/img/aviation/background-2.jpg")
        }
    },
    components: {
        AppHeader: () => import("@/components/common/AppHeader.vue"),
        SmallFooter: () => import("@/components/common/SmallFooter.vue"),
    },
    mounted() {
        axios(`${BASE_URL}/api/header`, {
            method: "GET",
        }).then((res) => {
            this.header = res.data;
        });

        axios(`${BASE_URL}/api/footer`, {
            method: "GET",
        }).then((res) => {
            this.footer = res.data;
        });

        axios(`${BASE_URL}/api/page/aviation`, {
            method: "GET",
        }).then((res) => {
            this.data = res.data[0];
        });
    },
}
</script>

<style lang="scss" scoped>
@import "@/assets/styles/common/adaptive-value.scss";

main {
    @include adaptive-value('margin-left', 126, 15, 1);
    @include adaptive-value('margin-right', 126, 15, 1);
    @include adaptive-value('margin-bottom', 48, 30, 1);
}
</style>
