<template>
    <div class="home">
        <AppHeader v-if="header" :header="header" />

        <!-- <main v-if="data">
            <div class="container">
                <h1 class="home__title">{{ data.title }}</h1>
                <div v-html="data.content" class="html_content"></div>
            </div>
        </main> -->

        <div class="industry">
            <div class="aboutUs__backgroundImage" :style="{ backgroundImage: `url(${background})` }">
                <h1 class="page__subtitle">{{ data.title }}</h1>
            </div>
            <div class="container">
                <img class="industry__icon" src="../assets/img/about-us/icon12.svg" alt="">
            </div>

            <div class="industry__background" :style="{ backgroundImage: `url(${background2})` }">
                <div class="container">
                    <div class="industry__block">
                        <h2 class="industry__title">The Need for a Robust Network Architecture</h2>
                        <p class="industry__text">In today's digital age, businesses are heavily reliant on seamless, uninterrupted connectivity. As they expand and diversify, the underlying network infrastructure must not only support but also catalyze this growth. A well-designed enterprise network architecture becomes the backbone of a company's operations, ensuring data flow and communication are at their peak efficiency.</p>

                        <h2 class="industry__title">Customized Solutions for Unique Challenges</h2>
                        <p class="industry__text">Every business has its unique set of requirements and challenges. At Urtica, we begin by understanding these specific needs. Whether you're integrating a new application, planning for cloud migration, or setting up a remote branch, our network designs are always tailor-made to align with your strategic goals.</p>

                        <h2 class="industry__title">Scalability at Its Core</h2>
                        <p class="industry__text">One of the main concerns businesses have is how their network will handle future growth. Our network solutions are crafted with scalability as a central feature. This ensures that as your business expands, your network is ready to accommodate the increased load without compromising on speed or security.</p>

                        <h2 class="industry__title">Security and Compliance</h2>
                        <p class="industry__text">In an era of increasing cyber threats, securing your network becomes paramount. Our team not only designs but also implements security protocols ensuring your data remains protected. Furthermore, we ensure that all our solutions comply with the latest industry standards and regulations, giving you peace of mind.</p>
                    </div>
                </div>
            </div>


            <div class="aboutUs__consultancy">
                <div class="container">
                    <h2 class="industry__title">Performance Optimization</h2>
                    <p class="industry__text">A network that's not optimized can lead to reduced efficiency, downtime, and even financial losses. Our experts employ the latest tools and techniques to ensure your network delivers optimal performance, ensuring smooth operations and increased productivity.</p>

                    <h2 class="industry__title">Future-Proofing Your Business</h2>
                    <p class="industry__text">The tech world is ever-evolving, with new advancements and innovations constantly emerging. Our team remains updated with the latest trends and developments in the realm of enterprise network architecture. This proactive approach ensures that your business is always a step ahead, ready to adapt and thrive in a changing landscape.</p>

                    <h2 class="industry__title">End-to-End Support and Maintenance</h2>
                    <p class="industry__text">Our commitment doesn't end with the design and implementation of your network. We believe in forming long-term partnerships, offering end-to-end support, regular maintenance, and updates. With Urtica by your side, you have a dedicated team ensuring your network is always up and running at its best.</p>

                    <h2 class="industry__title">Conclusion</h2>
                    <p class="industry__text">Enterprise Network Architecture is not just about connecting devices; it's about creating a resilient, adaptable, and high-performing ecosystem that drives business success. At Urtica, we combine our deep expertise with a personalized approach, ensuring your network is not just a support system, but a catalyst for growth. Join hands with us and propel your business into a future of limitless possibilities.</p>
                </div>
            </div>
        </div>

        <SmallFooter v-if="footer" :footer="footer" />
    </div>
</template>

<script>
import axios from "axios";
import { BASE_URL } from "@/constants/constants.js"
export default {
    data() {
        return {
            header: null,
            footer: null,
            data: null,
            background: require("@/assets/img/enterprise-architecture/background-1.webp"),
            background2: require("@/assets/img/enterprise-architecture/background-2.webp")
        }
    },
    components: {
        AppHeader: () => import("@/components/common/AppHeader.vue"),
        SmallFooter: () => import("@/components/common/SmallFooter.vue"),
    },
    mounted() {
        axios(`${BASE_URL}/api/header`, {
            method: "GET",
        }).then((res) => {
            this.header = res.data;
        });

        axios(`${BASE_URL}/api/footer`, {
            method: "GET",
        }).then((res) => {
            this.footer = res.data;
        });

        axios(`${BASE_URL}/api/page/enterprise_network_architecture`, {
            method: "GET",
        }).then((res) => {
            this.data = res.data[0];
        });
    },
}
</script>

<style lang="scss" scoped>@import "@/assets/styles/common/adaptive-value.scss";

main {
    @include adaptive-value('margin-left', 126, 15, 1);
    @include adaptive-value('margin-right', 126, 15, 1);
    @include adaptive-value('margin-bottom', 48, 30, 1);
}</style>
