<template>
    <div class="home">
        <AppHeader v-if="header" :header="header" />

        <!-- <main v-if="data">
            <div class="container">
                <h1 class="home__title">{{ data.title }}</h1>
                <div v-html="data.content" class="html_content"></div>
            </div>
        </main> -->

        <div class="industry atmandcns">
            <div class="aboutUs__backgroundImage" :style="{ backgroundImage: `url(${background})` }">
                <h1 class="page__subtitle">{{ data.title }}</h1>
            </div>
            <div class="container">
                <img class="industry__icon" src="../assets/img/about-us/icon11.svg" alt="">
            </div>

            <div class="industry__background" :style="{ backgroundImage: `url(${background2})` }">
                <div class="container">
                    <div class="industry__block">
                        <p class="industry__text">Aviation is not just about flying; it revolves around the intricate meshwork of systems that underpin it. At Urtica, our consultants delve into the intricate aspects of the Aviation's ATM and CNS sectors. We ensure that your operations not only meet the regulatory benchmarks but transcend them, positioning you firmly ahead of the competitive curve. Entrust us to guide you seamlessly through the multifaceted domains of aviation systems.</p>
                    </div>
                </div>
            </div>

            <div class="industry__block">
                <div class="container">
                    <ul class="consultancy__list">
                        <li class="consultancy__list-item">
                            <header class="consultancy__list-item__header">
                                <h3>1. ATM</h3>
                                <img src="../assets/img/consultancy/icon1.svg" alt="">
                            </header>

                            <ul class="industry__list">
                                <li class="industry__item">Control tower design</li>
                                <li class="industry__item">Analytica dashboards</li>
                                <li class="industry__item">Operational concept development</li>
                                <li class="industry__item">Procedures design</li>
                                <li class="industry__item">Future of ATM/ATS (iCWP)</li>
                                <li class="industry__item">ATFCM</li>
                            </ul>
                        </li>
                        <li class="consultancy__list-item">
                            <header class="consultancy__list-item__header">
                                <h3>2. CNS</h3>
                                <img src="../assets/img/consultancy/icon2.svg" alt="">
                            </header>

                            <ul class="industry__list">
                                <li class="industry__item">Navigational Aids</li>
                                <li class="industry__item">Communication systems & ATM network</li>
                                <li class="industry__item">Surveillance & Space-based ADS-B</li>
                                <li class="industry__item">Meteorogical systems</li>
                                <li class="industry__item">Visual and Surface Guidance systems</li>
                            </ul>
                        </li>
                        <li class="consultancy__list-item">
                            <header class="consultancy__list-item__header">
                                <h3>3. Networks & Virtualization</h3>
                                <img src="../assets/img/consultancy/icon3.svg" alt="">
                            </header>

                            <ul class="industry__list">
                                <li class="industry__item">Navigational Aids</li>
                                <li class="industry__item">Communication systems & ATM network</li>
                                <li class="industry__item">Surveillance & Space-based ADS-B</li>
                                <li class="industry__item">Meteorogical systems</li>
                                <li class="industry__item">Visual and Surface Guidance systems</li>
                            </ul>
                        </li>
                        <li class="consultancy__list-item">
                            <header class="consultancy__list-item__header">
                                <h3>4. Digital towers</h3>
                                <img src="../assets/img/consultancy/icon4.svg" alt="">
                            </header>

                            <ul class="industry__list">
                                <li class="industry__item">Strategy & Cost Benefit Analysis</li>
                                <li class="industry__item">Workplace Design</li>
                                <li class="industry__item">Safety Assessment</li>
                                <li class="industry__item">Certification Processes</li>
                                <li class="industry__item">Training and Transition</li>
                            </ul>
                        </li>
                        <li class="consultancy__list-item">
                            <header class="consultancy__list-item__header">
                                <h3>5. Drones & UAS</h3>
                                <img src="../assets/img/consultancy/icon1.svg" alt="">
                            </header>

                            <ul class="industry__list">
                                <li class="industry__item">Advanced Air Mobility</li>
                                <li class="industry__item">Urban  Air Mobility</li>
                                <li class="industry__item">Integration with manned aviation</li>
                                <li class="industry__item">Operational Concepts</li>
                            </ul>
                        </li>
                        <li class="consultancy__list-item">
                            <header class="consultancy__list-item__header">
                                <h3>6. Professional services</h3>
                                <img src="../assets/img/consultancy/icon1.svg" alt="">
                            </header>

                            <ul class="industry__list">
                                <li class="industry__item">Project management</li>
                                <li class="industry__item">Digital services</li>
                                <li class="industry__item">Supplier system specification and procurement management</li>
                                <li class="industry__item">Contact and Installation Supervision</li>
                                <li class="industry__item">Technical Advice & Technical Audits</li>
                                <li class="industry__item">Training seminars and On Job Training</li>
                            </ul>
                        </li>
                    </ul>
                </div>
            </div>

            <div class="aboutUs__consultancy">
                <div class="container">
                    <p>Dive deeper into what Urtica offers. With our decades of cumulative expertise in aviation consultancy, we're equipped to address the nuances and intricacies of both ATM and CNS domains. As the aviation sector rapidly evolves, our dedicated team remains at the forefront, continuously updating our strategies to offer solutions that are not just relevant today but are future-ready. Our commitment is not only to keep our clients compliant but to position them as trendsetters in the aviation world. As you soar higher in the skies of ambition, let Urtica be the trusted partner guiding your ascent, ensuring that every move is calculated, efficient, and forward-thinking. Together, we'll not just navigate the present but also architect the future of aviation.</p>
                </div>
            </div>
        </div>

        <SmallFooter v-if="footer" :footer="footer" />
    </div>
</template>

<script>
import axios from "axios";
import { BASE_URL } from "@/constants/constants.js"
export default {
    data() {
        return {
            header: null,
            footer: null,
            data: null,
            background: require("@/assets/img/ATMandCNS/background-1.png"),
            background2: require("@/assets/img/ATMandCNS/background-2.webp")
        }
    },
    components: {
        AppHeader: () => import("@/components/common/AppHeader.vue"),
        SmallFooter: () => import("@/components/common/SmallFooter.vue"),
    },
    mounted() {
        axios(`${BASE_URL}/api/header`, {
            method: "GET",
        }).then((res) => {
            this.header = res.data;
        });

        axios(`${BASE_URL}/api/footer`, {
            method: "GET",
        }).then((res) => {
            this.footer = res.data;
        });

        axios(`${BASE_URL}/api/page/aviation-consultancy`, {
            method: "GET",
        }).then((res) => {
            this.data = res.data[0];
        });
    },
}
</script>

<style lang="scss" scoped>@import "@/assets/styles/common/adaptive-value.scss";

main {
    @include adaptive-value('margin-left', 126, 15, 1);
    @include adaptive-value('margin-right', 126, 15, 1);
    @include adaptive-value('margin-bottom', 48, 30, 1);
}</style>
