<template>
  <div class="home">
    <AppHeader 
      v-if="header" 
      :header="header" 
    />

    <main v-if="urtica">
      <PageIntro v-if="urtica.intro" :intro="urtica.intro" />
      <CompanyBlock v-if="urtica.company" :company="urtica.company" />
      <AdvantagesBlock
        v-if="urtica.advantages"
        :advantages="urtica.advantages"
      />
      <WhoWeHelp v-if="urtica.help" :help="urtica.help" />
    </main>

    <AppFooter 
      v-if="footer" 
      :footer="footer" 
    />
    <ContactModal />

    <!-- <solutions-block v-if="urtica.solutions" :solutions="urtica.solutions" />
    <industries-block
      v-if="urtica.industries"
      :industries="urtica.industries"
    /> -->
  </div>
</template>

<script>
import axios from "axios";
import { BASE_URL } from "@/constants/constants.js"
export default {
  name: "HomeView",
  components: {
    AppHeader: () => import("@/components/common/AppHeader.vue"),
    PageIntro: () => import("@/components/blocks/PageIntro.vue"),
    AdvantagesBlock: () => import("@/components/blocks/AdvantagesBlock.vue"),
    // SolutionsBlock: () => import("@/components/blocks/SolutionsBlock.vue"),
    WhoWeHelp: () => import("@/components/blocks/WhoWeHelp.vue"),
    // IndustriesBlock: () => import("@/components/blocks/IndustriesBlock.vue"),
    CompanyBlock: () => import("@/components/blocks/CompanyBlock.vue"),
    AppFooter: () => import("@/components/common/AppFooter.vue"),
    ContactModal: () => import("@/components/modals/ContactModal.vue"),
  },
  data() {
    return {
      header: null,
      footer: null,
      urtica: null,
    };
  },
  created() {
    axios(`${BASE_URL}/api/header`, {
      method: "GET",
    }).then((res) => {
      this.header = res.data;
    });

    axios(`${BASE_URL}/api/footer`, {
      method: "GET",
    }).then((res) => {
      this.footer = res.data;
    });

    axios(`${BASE_URL}/api/main_page`, {
      method: "GET",
    }).then((res) => {
      this.urtica = res.data;
    });
  },
};
</script>
